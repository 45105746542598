<template>
  <div v-if="loaded" class="PanoFullScreen">
    <Pano :baseData="{form,params}" :skinData="skinData" @changePano="changePano" :propComponents="propComponents"></Pano>
  </div>
  <div v-else>
    <i class="fas fa-spinner fa-pulse"></i>
    加载中…
  </div>
</template>
<script>
  import { Pano } from 'common'
  import Skin from 'common/src/components/PanoSkins/EditPublicPanoSkin.json'
  import GroupUrls from '@/components/GroupUrls'
  export default {
    scrollToTop: true,
    components: {
      Pano
    },
    data() {
      return {
        loaded: false,
        form: null,
        skinData: Skin,
        propComponents: {
          GroupUrls: GroupUrls
        },
        params: {
          groupId: this.$route.params.groupId
        }
      }
    },
    computed: {
    },
    watch: {
    },
    created() {
    },
    mounted() {
      console.log(this.skinData.sences)
      for (var si in this.skinData.sences) {
        var s = this.skinData.sences[si]
        s.components.push({
          name: 'GroupUrls'
        })
        for (var ci in s.components) {
          var c = s.components[ci]
          if (c.key == 'BreakLeave') {
            console.log(si, c)
            if (c.config) {
              c.config.closeName = 'GroupPanoList'
            } else {
              c.config = { closeName: 'GroupPanoList' }
            }
          }
        }

      }
      this.loadData()
    },
    destroyed() {
    },
    methods: {
      changePano(pano) {
        this.pano = pano
      },
      loadData() {
        this.$axios.get(`/Api/GroupAdmin/Panos/${this.$route.params.groupId}/${this.$route.params.panoId}`)
          .then((response) => {
            let val = response.data
            if (val.title == '无标题') val.title = null;
            this.form = val;
            this.loaded = true;
          })
          .catch((error) => {
            console.error(error)
          })
      },
    },
  }
</script>
<style scoped>
  .PanoFullScreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1050;
  }
</style>
