<template lang="html">
  <div class="card card-primary card-outline TagList">
    <div class="card-header p-1">
      <div class="card-title">
        <button type="button" class="btn btn-primary btn-sm mx-1" v-on:click="create()" title="添加"><i class="fa fa-plus"></i></button>
        <button type="button" class="btn btn-default btn-sm mx-1" v-on:click="loadData()" title="刷新"><i class="fas fa-sync"></i></button>
      </div>
      <div class="card-tools mr-0">
        <div class="input-group input-group-sm m-0">
          <input type="text" class="form-control" placeholder="搜索" v-model="query.key" v-on:change="search()">
          <div class="input-group-append">
            <button type="button" class="btn btn-primary" v-on:click="search()">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- /.box-header -->
    <div class="card-body p-1">
      <div class="mailbox-controls">
        <div class="form-inline visible-md-inline-block visible-lg-inline-block visible-sm-inline-block">
          <div class="form-group form-group-sm">
            <label>类型</label>
            <select class="form-control" v-model:value="query.type" v-on:change="search()" placeholder="类型">
              <option :value="null">所有</option>
              <option v-for="i in types" v-bind:value="i">{{i.key}}</option>
            </select>
          </div>
        </div>
        <div class="pull-right">
          <div class="form-inline">
            <div class="form-group form-group-sm">
            </div>
          </div>
        </div>
        <!-- /.pull-right -->
      </div>
      <vue-bootstrap4-table :rows="list"
                            :columns="columns"
                            :config="config"
                            :classes="classes"
                            @on-change-query="onChangeQuery">
        <template slot="sort-asc-icon">
          <i class="fas fa-sort-up"></i>
        </template>
        <template slot="sort-desc-icon">
          <i class="fas fa-sort-down"></i>
        </template>
        <template slot="no-sort-icon">
          <i class="fas fa-sort"></i>
        </template>
        <template slot="empty-results">
          没有数据
        </template>
        <template slot="active" slot-scope="props">
          <button class="btn btn-default btn-xs mx-1" title="编辑" @click="edit(props.row)">
            <i class="fas fa-pen-square"></i>
          </button>
          <button class="btn btn-default btn-xs mx-1" title="删除" @click="opendelete(props.row)">
            <i class="fas fa-trash"></i>
          </button>
        </template>
        <template slot="closed" slot-scope="props">
          {{props.row.closed ? "是" : "否"}}
        </template>
      </vue-bootstrap4-table>
    </div>
    <!-- Loading (remove the following to stop the loading)-->
    <div class="overlay" v-if="this.$store.state.loading">
      <i class="fas fa-spinner fa-pulse"></i>
    </div>
    <!-- end loading -->
    <div class="card-footer p-2">
      <paging :page-index="pager.pageIndex"
              :total="pager.totalRecords"
              :page-size="pager.pageSize"
              :per-pages="3"
              :show-go="true"
              @change="pageChange"
              :onPagerChange="pageChange">
      </paging>
    </div>
    <Modal ref="Edit">
      <template slot="header">
        <h4 class="modal-title">{{ item.id!=null ? '修改标签':  '新建标签' }}</h4>
      </template>
      <form class="form-horizontal">
        <div class="form-group">
          <label for="editName" class="col-sm-2 control-label">名称</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" id="editName" placeholder="名称" v-model.trim="item.name">
          </div>
        </div>
        <div class="form-group">
          <label for="type" class="col-sm-2 control-label">标签类型</label>
          <div class="col-sm-10">
            <select class="form-control" id="type" placeholder="标签类型" v-model="item.type" v-if="item.guid==null">
              <option v-for="i in types" :value="i.key">
                {{i.key}}
              </option>
            </select>
            <input class="form-control" readonly :value="item.type" v-else />
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-offset-2 col-sm-10">
            <div class="checkbox">
              <label>
                <input type="checkbox" v-model="item.closed"> 关闭
              </label>
            </div>
          </div>
        </div>
      </form>
      <template slot="footer">
        <button type="button" class="btn btn-default pull-left" @click="$refs.Edit.hide()">取消</button>
        <button type="button" class="btn btn-primary" @click="save()" :disabled="uploading">保存</button>
      </template>
    </Modal>
    <Modal ref="Delete">
      <template slot="header">
        <h4 class="modal-title">删除标签</h4>
      </template>
      <form class="form-horizontal">
        {{item}}
      </form>
      <template slot="footer">
        <button type="button" class="btn btn-default pull-left" @click="$refs.Delete.hide()">取消</button>
        <button type="button" class="btn btn-danger" @click="del(item)" :disabled="uploading">删除</button>
      </template>
    </Modal>
  </div>
</template>

<script>
  import { Modal } from 'common'
  //import Modal from '@/components/Modal'
  export default {
    components: {
      Modal
    },
    data() {
      return {
        list: [],
        item: this.newItem(),
        columns: [
          { name: 'active', label: '操作', column_classes: "text-nowrap c1", row_classes: "text-nowrap" },
          { name: 'type', label: '类型', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap c_type" },
          { name: 'name', label: '名称', sort: true, initial_sort: true, initial_sort_order: 'asc', uniqueId: true, column_classes: "text-nowrap", row_text_alignment: "text-left" },
          { name: 'addTime', label: '添加时间', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap c_dt", row_classes: "text-nowrap" },
          { name: 'closed', label: '关闭', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap c2" },
        ],
        classes: {
          tableWrapper: "table-responsive",
          table: "table-bordered table-sm table-hover",
          //row: {
          //    "my-row my-row2": true,
          //    "function-class": function (row) {
          //        return row.id == 1
          //    }
          //},
          //cell: {
          //    "my-cell my-cell2": true,
          //    "text-danger": function (row, column, cellValue) {
          //        return column.name == "salary" && row.salary > 2500
          //    }
          //}
        },
        config: {
          //checkbox_rows: true,
          //rows_selectable: true,
          card_mode: false,
          pagination: false,
          pagination_info: false,
          global_search: {
            visibility: false,
          },
          show_refresh_button: false,
          show_reset_button: false,
          multi_column_sort: false,
          server_mode: true,
        },
        pager: {
          prePageSize: [10, 20, 50, 100],
          pageIndex: 1,
          pageSize: 1,
          totalPages: 0,
          totalRecords: 0
        },
        query: { ...this.getDefaultQuery(), ...this.$route.query },
        types: [],
        updateing: false,
        uploading: false,
        tagUrl: this.groupId ? `/Api/GroupAdmin/Tags/${this.groupId}` : '/Api/My/Tags'
      }
    },
    inject: ['layoutData'],
    computed: {
      groupId() {
        return (this.layoutData || {}).groupId || this.$route.params.groupId
      }
    },
    created() {
      this.tagUrl = this.groupId ? `/Api/GroupAdmin/Tags/${this.groupId}` : '/Api/My/Tags'
      this.loadData()
      this.loadTypes()
    },
    methods: {
      newItem() {
        return {
          guid: null,
          name: null,
          closed: false,
          type: '默认',
        }
      },
      loadTypes() {
        var _this = this
        var url = `${this.tagUrl}/Types`
        this.$axios.get(`${this.tagUrl}/Types`).then(res => {
          _this.types = res.data
        })
      },
      loadData() {
        var params = {
          ...this.query,
        }
        this.$axios.get(this.tagUrl, { params: params })
          .then((response) => {
            console.log(response)
            this.list = response.data.data
            this.pager.pageIndex = response.data.pageIndex
            this.pager.pageSize = response.data.pageSize
            this.pager.totalPages = response.data.totalPages
            this.pager.totalRecords = response.data.totalRecords

          }).catch((error) => {
            console.log(error)
          })

      },
      getDefaultQuery() {
        return {
          page: 1,
          pageSize: 20,
          sort: "addTime",
          order: "desc",
          key: null,
          type: null
        }
      },
      getDifferentQuery(query) {
        var dif = {}
        var all = { ...this.query, ...query }
        var def = this.getDefaultQuery()
        for (var i in all) {
          if (def[i] != all[i]) {
            dif[i] = all[i]
          }
        }
        return dif
      },
      setQuery(newquery) {
        if (!newquery) {
          this.query = this.getDefaultQuery()
        }
        var dif = this.getDifferentQuery(newquery)
        console.log(this.$route.query, dif)
        if (!this.$isObjectValueEqual(this.$route.query, dif)) {
          this.$router.push({ query: dif })
        }
      },
      search() {
        this.setQuery({ page: 1 })
      },
      pageChange(page, pageSize) {
        this.setQuery({ page, pageSize })
      },
      onChangeQuery(queryParams) {
        var sort = queryParams.sort[0]
        this.setQuery({ sort: sort.name, order: sort.order })
      },
      loadItem(id) {
        this.$axios.get(`${this.tagUrl}/${id}`)
          .then((response) => {
            //console.log(response)
            this.item = response.data
          })
          .catch((error) => {
            console.log(error)
          })
      },
      reset() {
        this.item = this.newItem()
        if (this.query.type && this.query.type != '') {
          this.item.type = this.query.type.key
        } else {
          this.item.type = '默认'
        }
      },
      create() {
        this.$refs.Edit.show()
        this.reset()
      },
      edit(item) {
        console.log(item)
        this.$refs.Edit.show()
        this.loadItem(item.guid)
      },
      opendelete(item) {
        console.log(item)
        this.$refs.Delete.show()
        this.loadItem(item.guid)
      },
      del(item) {
        this.updateing = true
        console.log(item)
        this.$axios.delete(`${this.tagUrl}/${item.guid}`)
          .then(res => {
            console.log(res)
            this.$refs.Delete.hide()
            this.loadData()
            this.updateing = false
          })
          .catch((error) => {
            console.log(error)
            this.updateing = false
          })
      },
      save() {
        this.updateing = true
        if (this.item.guid != null) {
          this.$axios.put(`${this.tagUrl}/${this.item.guid}`, this.item)
            .then((response) => {
              this.reset()
              this.$refs.Edit.hide()
              this.loadData()
              this.updateing = false
            })
            .catch((error) => {
              if (error.response.data) {
                this.$message({
                  message: error.response.data,
                  type: 'error',
                  zIndex: 9999,
                })
              }
              this.updateing = false
            })
        } else {
          this.$axios.post(this.tagUrl, this.item)
            .then((response) => {
              this.reset()
              this.$refs.Edit.hide()
              this.loadData()
              this.updateing = false
            })
            .catch((error) => {
              if (error.response.data) {
                this.$message({
                  message: error.response.data,
                  type: 'error',
                  zIndex: 9999,
                })
              }
              this.updateing = false
            })
        }
      },
    },
    beforeRouteUpdate(to, from, next) {
      this.query = { ...this.getDefaultQuery(), ...to.query }
      this.loadData()
      next()
    }
  }
</script>

<style lang="css">
  .TagList .c1 {
    width: 90px;
  }

  .TagList .c2 {
    width: 60px;
  }

  .TagList .c_dt {
    width: 155px;
  }

  .TagList .c_type {
    width: 120px;
  }
</style>
